<div class="col-md-12 col-sm-12 col-xs-12">
    <div class="container">
        <div class="col-md-12">
            <h2 class="text-dark">
                <i class="fa fa-camera"></i> Quiz Mangement
            </h2>
            <hr>
        </div>
    </div>
</div>


<div class="card">
    <div class="card-header">
        Quiz Mangement

        <a class="btn btn-outline-light md-block text-dark" (click)="reload()" routerLinkActive="active "> <i class="fa fa-refresh fa-lg" aria-hidden="true"></i></a>

    </div>
    <div class="card-body">



        <div class="container-fluid" style="background-color:white; padding: 20px;">

            <div *ngIf='feed' class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                </div>
            </div>


            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger " class="table table-striped table-bordered table-sm row-border hover" style="width: 100%; font-size:medium;">
                <thead *ngIf='!feed'>
                    <tr>
                        <th> id </th>
                        <th> child_name </th>
                        <th> book_name</th>
                        <th> question_content</th>
                        <th> option_content</th>
                        <!-- <th> is_correct</th> -->
                        
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let datas of feedData">
                        <td>{{ datas.id }}</td>
                        <td>{{ datas.child_name }}</td>
                        <td>{{ datas.book_name }}</td>
                        <td>{{ datas.question_content }}</td>
                        <td [ngStyle]="datas.is_correct=='1' ? {'color': 'green'}:{'color': 'red'}" style="font-weight: 800;">{{ datas.option_content }}</td>
                        <!-- <td [ngStyle]="datas.is_correct=='1' ? {'color': 'green'}:{'color': 'red'}" style="font-weight: 800;">{{ datas.is_correct}}</td> -->
                        <!-- <td mat-cell *matCellDef="let element"  [ngStyle]="datas.is_correct=='1' ? {'color': 'green'}:{'color': 'red'}" style="font-weight: 800;"> {{datas.is_correct}} </td> -->

                        

                        <td>
                            <a mwlConfirmationPopover popoverTitle="Logout" popoverMessage="Want to delete Quiz Record?" placement="left" (confirm)="deletepost(datas.id)" (cancel)="false">
                                <i class="fa fa-trash-o fa-lg " style="color:brown; " aria-hidden="true "></i></a>&nbsp;&nbsp;&nbsp;&nbsp;

                            <a [routerLink]="[ '/admin/viewbook',datas.book_id] "> <i class="fa fa-edit fa-lg " style="color: green; " aria-hidden="true "></i></a>
                            <hr>
                            <a [href]="datas.book_url"> <i class="fa fa-download fa-lg " style="color:blue; " aria-hidden="true "></i></a>

                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>