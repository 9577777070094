import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AdminService } from 'src/app/Service/admin.service';

@Component({
  selector: 'app-getAllQuiz',
  templateUrl: './getAllQuiz.component.html',
  styleUrls: ['./getAllQuiz.component.css']
})
export class GetAllQuiz implements OnInit {

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  feedData: any;
  feed = true;
  constructor(private adminService: AdminService,private route:Router,private toastr: ToastrService,@Inject(HttpClient) private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [10, 15, 20, 25, 30],
      processing: true,
      responsive:true,
    
      dom: 'Bfltip',      
      buttons: [
        'print',
        'excel',
       
      ],
    };

    this.adminService.getAllQuizRecords().subscribe(
      (response: any) => {
        this.feedData = response.data;
        
      });

  }


  ngAfterViewInit(): void { 
        
    setTimeout(() => {
        this.dtTrigger.next();
        this.feed = false;
      }, 500);

  }


  deletepost(id){
    this.adminService.delQuizRecord(id).subscribe((response:any)=>{  
      
      if(response.code == 1){
        this.toastr.success("post Deleted!!");
        this.ngOnInit();
      }else{
        this.toastr.error("oops something went wrong!!");
      }

    });
  }


  reload(){
    location.reload();
  }
}
