// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//* below commented code is pointing the old bucket
// export const environment = {
//   production: false,
// //  APIURL: 'https://admin.edsoma.net/api/v1/admin',
//  APIURL: 'https://test.edsoma.net/api/v1/admin',
//   //  APIURL: 'http://18.211.63.129:3300/api/v1/admin',
//   bucket:'https://edsoma-development.s3.amazonaws.com/',
//   book_image: 'https://edsoma-development.s3.amazonaws.com/books/',
//   profile_image: 'https://edsoma-development.s3.amazonaws.com/profile_image/',
//   category_image: 'https://edsoma-development.s3.amazonaws.com/category/',
//   books_data: 'https://edsoma-development.s3.amazonaws.com/books_data/',

// };
//* below commented code is pointing the new buckets
export const environment = {
  production: false,
  //  APIURL: 'http://qa-alb-1400681456.us-east-1.elb.amazonaws.com/api/v1/admin',
  APIURL: 'https://test.edsoma.net/api/v1/admin',
  bucket:'https://edsoma-books.s3.amazonaws.com/',
  book_image: 'https://edsoma-books.s3.amazonaws.com/',
  profile_image: 'https://edsoma-profile-pictures.s3.amazonaws.com/',
  category_image: 'https://edsoma-books.s3.amazonaws.com/',
  books_data: 'https://edsoma-books.s3.amazonaws.com/',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.



//************ Developer comment *************** 
//  In this file what i changed .
// 1.Here , i change the url of book_image,bucket,profile_image,category_image, we need to change this because of changing s3 buckets
