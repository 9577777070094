import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {MatTableModule} from '@angular/material/table';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './Components/login/login.component';
import { DefaultComponent } from './Components/default/default.component';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { HomeComponent } from './Components/home/home.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { AboutusComponent } from './Components/aboutus/aboutus.component';
import { UserComponent } from './Components/user/user.component';
import { CategoryComponent } from './Components/category/category.component';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ViewuserComponent } from './Components/viewuser/viewuser.component';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { RfeedsComponent } from './Components/rfeeds/rfeeds.component';
import { ViewpostComponent } from './Components/viewpost/viewpost.component';
import { FeedsComponent } from './Components/feeds/feeds.component';
import { FaqComponent } from './Components/faq/faq.component';
import { PrivacyComponent } from './Components/privacy/privacy.component';
import { TermsComponent } from './Components/terms/terms.component';
import { ChangepasswordComponent } from './Components/changepassword/changepassword.component';
import { ValidateEqualModule } from 'ng-validate-equal';
import { CategoryeditComponent } from './Components/categoryedit/categoryedit.component';
import { AddbookComponent } from './Components/addbook/addbook.component';
import { PushComponent } from './Components/push/push.component';
import { EmailComponent } from './Components/email/email.component';
import { EventsComponent } from './Components/events/events.component';
import { AddeventComponent } from './Components/addevent/addevent.component';
import { DatePipe } from '@angular/common';
import { VieweventComponent } from './Components/viewevent/viewevent.component';
import { ShowStudent } from './Components/showstudent/showstudent.component';
import { ImportStudentComponent } from './Components/importstudent/importstudent.component';
import { ShowTeacher } from './Components/showteacher/showteacher.component';
import { ImportTeacherComponent } from './Components/importteacher/importteacher.component';
import { ShowSchool } from './Components/showschool/showschool.component';
import { ImportSchoolComponent } from './Components/importschool/importschool.component';
import { ShowClasses } from './Components/showclasses/showclasses.component';
import { ImportClassesComponent } from './Components/importclasses/importclasses.component';
import { ViewSchoolComponent } from './Components/viewschool/viewschool.component';
import { GetAllQuiz } from './Components/getAllQuiz/getAllQuiz.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DefaultComponent,
    DashboardComponent,
    HomeComponent,
    AboutusComponent,
    UserComponent,
    CategoryComponent,
    ViewuserComponent,
    RfeedsComponent,
    ViewpostComponent,
    FeedsComponent,
    FaqComponent,
    PrivacyComponent,
    TermsComponent,
    ChangepasswordComponent,
    CategoryeditComponent,
    AddbookComponent,
    PushComponent,
    EmailComponent,
    EventsComponent,
    AddeventComponent,
    VieweventComponent,
    ShowStudent,
    ImportStudentComponent,
    ImportTeacherComponent,
    ShowTeacher ,
    ShowSchool,
    ImportSchoolComponent,
    ShowClasses,
    ImportClassesComponent,
    ViewSchoolComponent,
    GetAllQuiz
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CKEditorModule,
    DataTablesModule,
    FormsModule,
    HttpClientModule,
    ValidateEqualModule,
    MatTableModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType:'danger',
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      progressBar: true,
      progressAnimation: 'increasing',
      closeButton:true
    })
    ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
